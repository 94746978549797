<template>
  <div id="modal" class="modal-moderar">
    <div class="modal-bg">
      <div class="modal-fechar" @click.exact="fecharModal"></div>
      <div class="modal-conteudo">
        <div class="conteudo-mensagem">
          <h2>Mensagem</h2>
          <p class="modal-texto">Justifique</p>
          <textarea placeholder="Escreva a aqui" class="modal-textarea" rows="15" v-model="comentario"></textarea>
          <button class="btn btn-laranja" @click="enviar()">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alterarStatus } from "@/services/cupom";

export default {
  name: "ModalModerar",
  data() {
    return {
      comentario: "",
    };
  },
  methods: {
    enviar() {
      const parametrosDaRota = {
        liberarPontuacao: this.$store.state.modal.paramsModal.idStatus,
        comentario: this.comentario,
        idCupom: this.$store.state.modal.paramsModal.idCupom,
        dataCompra: this.$store.state.modal.paramsModal.dataCompra,
      }
      alterarStatus(parametrosDaRota).then((resp) => {
        this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: resp.data.mensagem,
          tipo: resp.data.sucesso ? "sucesso" : "erro",
        });
        setTimeout(() => {
          document.location.reload(true);
        }, 1500);
      });
    },
    fecharModal() {
      this.$store.commit("SET_MODAL", { ativado: false });
    },
  },
};
</script>

<style lang="scss">
#modal.modal-moderar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 50px 25px 70px 25px;

  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .modal-bg {
    position: relative;
    z-index: 1;
    margin: auto;

    @media (max-width: 800px) {
      height: 100%;
    }

    &:before {
      content: "";
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
    }

    .modal-fechar {
      position: absolute;
      z-index: 1;
      top: 8px;
      right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        content: "";
        border-radius: 20px;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal-conteudo {
      position: relative;
      width: 100%;

      @media (max-width: 800px) {
        height: 100%;
      }
    }
  }

  .conteudo-mensagem {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    border-radius: 15px;
    max-width: 400px;
    height: auto;
    max-height: 425px;
    padding: 30px 20px 50px 20px;
    text-align: center;

    h2 {
      font-size: 35px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    p {
      font-size: 22px;
      line-height: 1.1em;
      word-wrap: break-word;
    }

    @media (max-width: 800px) {
      min-height: 0px;
      width: 100vw;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }

  textarea {
    display: block;
    padding: 5px;
    width: calc(100% - 12px);
    margin: 10px auto;
  }

  .btn-laranja {
    margin: 10px auto;
  }
}
</style>
